import './entry-tiles.scss';

class EntryTiles {
    constructor ($module, options) {
        const defaults = {
            resizeHandler: null,
            sliderBreakpoint: 1024
        };

        this.settings = Object.assign({}, defaults, options);

        this.$module = $module;
        this.$slider = this.$module.querySelector('[data-entry-tiles="slider"]');
        this.$sliderWrap = this.$module.querySelector('[data-entry-tiles="wrap"]');
        this.$tilesMobile = [].slice.call(this.$module.querySelectorAll('div[data-entry-tiles="tile"]:not(.is--desktop-only)'));
        this.$tilesDesktop = [].slice.call(this.$module.querySelectorAll('a[data-entry-tiles="tile"]:not(.is--mobile-only)'));
        this.tileCount = this.$slider.getAttribute('data-slider-length');
        this.$activeTile = null;
        this.activeTile = false;
        this.hoverMaxScroll = 0;
        this.scrollActive = false;
        this.editMode = this.$module.classList.contains('is--editmode');
        this.hover = true;

        this.resizeHandler = this.settings.resizeHandler;
    }

    init () {
        this.scrollActive = window.innerWidth >= this.settings.sliderBreakpoint;

        if (document.documentElement.classList.contains('no-csshover')) {
            this.hover = false;
        }

        this.checkForActive();
        this.setEvents();

        if (this.hover && !this.editMode) {
            this.setHover();
        }

        setTimeout(() => {
            this.setFirstTileMargin();
            this.setMaxHeight();
        }, 400);
    }

    setFirstTileMargin () {
        if (window.innerWidth < 1023) {
            this.$tilesMobile[0].classList.add('is--first-tile');
        }
    }

    checkForActive () {
        this.$activeTile = this.$module.querySelector('[data-entry-tiles="tile"] > div.is--active');

        if (this.$activeTile) {
            this.activeTile = true;
        }
    }

    setEvents () {
        if (this.hover) {
            this.$tilesDesktop.forEach((tile) => {
                ['mouseenter', 'focus'].forEach(evt =>
                    tile.addEventListener(evt, () => {
                        tile.style.zIndex = 3;

                        if (this.activeTile) {
                            this.$activeTile.classList.remove('is--active');
                            this.activeTile = false;
                        }
                    }, false)
                );

                ['mouseleave', 'blur'].forEach(evt =>
                    tile.addEventListener(evt, () => {
                        tile.style.zIndex = 2;
                    }, false)
                );
            });
        } else {
            this.$tilesDesktop.forEach((tile) => {
                if (window.innerWidth >= this.settings.sliderBreakpoint) {
                    tile.addEventListener('click', (e) => {
                        if (!tile.firstElementChild.classList.contains('is--active')) {
                            e.preventDefault();
                            e.stopPropagation();

                            this.checkForActive();

                            tile.firstElementChild.style.zIndex = 11;
                            tile.firstElementChild.classList.add('is--active');

                            if (this.activeTile) {
                                this.$activeTile.classList.remove('is--active');
                                this.$activeTile.style.zIndex = 9;
                                this.activeTile = false;
                            }
                        }
                    });
                }
            });
        }

        if (this.resizeHandler) {
            this.resizeHandler.customFunctions.push(() => {
                this.setMaxHeight();
            });
        }

        this.$sliderWrap.addEventListener('change', (e) => {
            if (e.target.classList.contains('flyout__input')) {
                if (e.target.checked === true) {
                    const $foundOpen = this.$sliderWrap.querySelector('input[type="checkbox"].flyout__input:checked:not([id="' + e.target.getAttribute('id') + '"]');
                    if ($foundOpen) {
                        $foundOpen.checked = false;
                    }
                    this.setOpenHeight(e.target);
                } else {
                    this.setCloseHeight(e.target);
                }
            }
        });
    }

    setOpenHeight ($input) {
        const $target = $input.parentElement.parentElement;
        const columnHeight = $target.getAttribute('data-normal-column-height');
        this.$slider.style.maxHeight = parseInt(columnHeight) + parseInt($target.getAttribute('data-diff-tile-height')) + 'px';

        setTimeout(() => {
            this.checkContentWidth();
        }, 1);
    }

    checkContentWidth () {
        const widthInterval = setInterval(() => {
            if (this.$slider.clientWidth < this.$slider.scrollWidth) {
                this.$slider.style.maxHeight = parseInt(this.$slider.style.maxHeight) + 40 + 'px';
            } else {
                clearInterval(widthInterval);
            }
        });
    }

    setCloseHeight ($input) {
        // const $target = $input.parentElement.parentElement;
        // this.$slider.style.maxHeight = parseInt($target.getAttribute('data-normal-column-height')) + 'px';

        setTimeout(() => {
            this.checkContentWidth();
        }, 1);
    }

    setMaxHeight () {
        this.scrollActive = window.innerWidth >= this.settings.sliderBreakpoint;
        this.hoverMaxScroll = this.$slider.scrollWidth - window.innerWidth;
        const width = window.innerWidth;

        if (width < this.settings.sliderBreakpoint) {
            if (!this.$module.classList.contains('is--desktop-only')) {
                let tilesInColumn = 0;
                let normalHeight = 0;
                let columns = 2;
                let totalNormalMaxHeight = 0;

                if (width >= 768) {
                    columns = 3;
                }

                if (width < this.settings.sliderBreakpoint) {
                    tilesInColumn = Math.ceil(this.$tilesMobile.length / columns);
                    let maxSingleHeight = 0;

                    for (let a = 0; a < columns; a++) {
                        normalHeight = 0;
                        maxSingleHeight = 0;
                        for (let i = 0; i < tilesInColumn; i++) {
                            if (this.$tilesMobile[i + (a * tilesInColumn)]) {
                                normalHeight += Math.ceil(this.$tilesMobile[i + (a * tilesInColumn)].offsetHeight);
                                maxSingleHeight = Math.ceil(this.$tilesMobile[i + (a * tilesInColumn)].scrollHeight);

                                this.$tilesMobile[i + (a * tilesInColumn)].setAttribute('data-diff-tile-height', maxSingleHeight - Math.ceil(this.$tilesMobile[i + (a * tilesInColumn)].offsetHeight));
                                this.$tilesMobile[i + (a * tilesInColumn)].setAttribute('data-normal-tile-height', Math.ceil(this.$tilesMobile[i + (a * tilesInColumn)].offsetHeight));
                            }
                        }

                        normalHeight = (normalHeight + 40) + (tilesInColumn * 40);

                        if (normalHeight > totalNormalMaxHeight) {
                            totalNormalMaxHeight = normalHeight;
                        }
                    }

                    this.$slider.style.maxHeight = totalNormalMaxHeight + 'px';
                    this.$tilesMobile.forEach(($tile) => {
                        $tile.setAttribute('data-normal-column-height', totalNormalMaxHeight);
                    });
                }
            }
        }
    }

    setHover () {
        const _this = this;

        let speed = 0;
        let scroll = 0;
        const container = this.$sliderWrap;
        _this.hoverMaxScroll = this.$slider.scrollWidth - window.innerWidth;
        let prevFrame = new Date().getTime();

        container.addEventListener('mousemove', (e) => {
            const mouseX = e.pageX - container.getBoundingClientRect().left;
            const mouseperc = 100 * mouseX / container.clientWidth;
            speed = mouseperc - 50;
        });

        container.addEventListener('mouseleave', () => {
            speed = 0;
        });

        const updateScroll = () => {
            if (_this.scrollActive) {
                const curFrame = new Date().getTime();
                const timeElapsed = curFrame - prevFrame;

                prevFrame = curFrame;

                if (speed !== 0) {
                    scroll += speed * timeElapsed / 125;
                    if (scroll < 0) scroll = 0;
                    if (scroll > _this.hoverMaxScroll) scroll = _this.hoverMaxScroll;
                    container.scrollLeft = scroll;
                }
            }
            window.requestAnimationFrame(updateScroll);
        };
        window.requestAnimationFrame(updateScroll);
    }
}

export { EntryTiles };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;
    const resizeHandler = eventDetails.$resize;

    const $entryTilesSliders = $context.querySelectorAll('[data-entry-tiles="main"]');

    if ($entryTilesSliders.length > 0) {
        $entryTilesSliders.forEach((entryTilesSlider) => {
            const $entryTilesSlider = new EntryTiles(entryTilesSlider, {
                resizeHandler: resizeHandler,
                sliderBreakpoint: 1024
            });
            $entryTilesSlider.init();
        });
    }
});
